<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <p>
          <strong>{{ $_strings.addendumContract.label.DOWNLOAD_TEMPLATE_ADENDUM }}</strong>
        </p>
      </v-col>
    </v-row>
    <v-form
      ref="form"
      lazy-validation
    >
      <!-- FORMAT CONTRACT -->
      <section class="white pa-10 elevation-1">
        <v-row class="d-flex">
          <v-col cols="12" sm="4" md="3" class="d-flex align-center">
            <p>{{ $_strings.contract.label.CONTRACT_FORMAT }}<span class="red--text">*</span></p>
          </v-col>
          <v-col cols="12" sm="auto">
            <v-radio-group
              v-model="form.companyType"
              @change="fetchCompanyList('')"
              :rules="rules.partnerRule"
              hide-details
              :disabled="readonly"
            >
              <v-radio
                :key="1"
                value="SHIPPER"
                label="Shipper">
              </v-radio>
              <v-radio
                :key="2"
                value="TRANSPORTER"
                label="Rekanan 3PL">
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" md="3">
            <span class="subtitle-1">{{ $_strings.contract.header.COMPANY_NAME}} <span class="red--text">*</span></span>
          </v-col>
          <v-col cols="12" md="9">
            <v-tooltip v-model="showTooltipCompanyName" left>
              <template v-slot:activator="{ }">
                <v-autocomplete
                  dense
                  outlined
                  item-text="companyName"
                  item-value="companyId"
                  placeholder="Nama Perusahaan"
                  :rules="rules.companyNameRules"
                  :items="itemsCompanyList"
                  v-model="form.companyId"
                  @change="($event) => setForm($event)"
                  :loading="isLoadingCompanyList"
                  @input.native="($event) => fetchCompanyList($event.target.value)"
                  :clearable="!readonly"
                  :disabled="readonly"
                  @focus="!form.companyType ? showTooltipCompanyName = true : showTooltipCompanyName = false"
                  @blur="showTooltipCompanyName = false"
                >
                </v-autocomplete>
              </template>
              <p>{{$_strings.contract.label.CONTRACT_FORMAT}} Belum Dipilih</p>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" md="3">
            <span class="subtitle-1">{{ $_strings.contract.label.MOU_NUMBER }}<span class="red--text">*</span></span>
          </v-col>
          <v-col cols="12" md="9">
            <v-text-field
              :value="form.mouNo"
              :rules="rules.mouRule"
              dense
              outlined
              readonly
              placeholder="Nomor MoU"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" md="3">
            <span class="subtitle-1">{{ $_strings.contract.header.CONTRACT_NUMBER}} <span class="red--text">*</span></span>
          </v-col>
          <v-col cols="12" md="9">
            <v-text-field
              height="30"
              outlined
              dense
              placeholder="Nomor Kontrak"
              v-model="form.contractNo"
              readonly
              aria-hidden="true"
              required
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" md="3">
            <span class="subtitle-1">{{ $_strings.contract.label.EFFECTIVE_PERIOD}} <span class="red--text">*</span></span>
          </v-col>
          <v-col md="3">
            <v-text-field
              height="30"
              outlined
              dense
              placeholder="Tanggal"
              :value="form.periodStart ? dayjs(form.periodStart).format('DD/MM/YYYY') : null"
              readonly
            >
            </v-text-field>
          </v-col>
          <v-col cols="auto">
            <span class="subtitle-1">{{ $_strings.contract.label.UNTIL}} <span class="red--text">*</span></span>
          </v-col>
          <v-col md="3">
            <v-text-field
              height="30"
              outlined
              dense
              placeholder="Tanggal"
              :value="form.periodEnd ? dayjs(form.periodEnd).format('DD/MM/YYYY') : null"
              readonly
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="4" md="3">
            <span class="subtitle-1">{{ $_strings.addendumContract.label.ADENDUM_SIGNED }} <span class="red--text">*</span></span>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              dense
              outlined
              item-value="name"
              item-text="name"
              placeholder="Tempat"
              :items="cityList"
              v-model="form.addendumLocation"
              @input.native="($event) => fetchCityList($event.target.value)"
              :rules="rules.cityRule"
              :loading="isLoadingCityList"
              :clearable="!readonly"
              :disabled="readonly"
            >
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-menu
              ref="signedDateShow"
              v-model="signedDateShow"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  prepend-inner-icon="mdi-calendar-month"
                  label="Tanggal"
                  single-line
                  outlined
                  dense
                  :value="form.addendumDate ? dayjs(form.addendumDate).format('DD/MM/YYYY') : null"
                  :rules="rules.dateRule"
                  aria-hidden="true"
                  readonly
                  persistent-hint
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.addendumDate"
                no-title
                @input="signedDateShow = false"
                :disabled="readonly"
                locale="id"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" md="3">
            <span class="subtitle-1">{{ $_strings.addendumContract.label.ADDENDUM_EFFECTIVE }} <span class="red--text">*</span></span>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-menu
              ref="effectiveDateShow"
              v-model="effectiveDateShow"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  prepend-inner-icon="mdi-calendar-month"
                  label="Tanggal"
                  single-line
                  outlined
                  dense
                  :value="form.addendumEffectiveDate ? dayjs(form.addendumEffectiveDate).format('DD/MM/YYYY') : null"
                  :rules="rules.dateRule"
                  aria-hidden="true"
                  readonly
                  persistent-hint
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.addendumEffectiveDate"
                no-title
                :disabled="readonly"
                @input="effectiveDateShow = false"
                :min="form.periodStart ? dayjs(form.periodStart).format('YYYY-MM-DD') : null"
                :max="form.periodEnd ? dayjs(form.periodEnd).format('YYYY-MM-DD') : null"
                locale="id"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </section>
      <!-- END FORMAT CONTRACT -->

      <!-- FORM PT.Fastana Logistik Indonesia -->
      <section class="white pa-10 mt-5 elevation-1">
        <v-row>
          <v-col>
            <p><strong>{{ $_strings.addendumContract.label.PT_FLI}}</strong></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" md="3" class="d-flex align-center">
            <p> {{ $_strings.addendumContract.label.NAME }}<span v-if="isRequiredMainSigner" class="red--text">*</span></p>
          </v-col>
          <v-col
            sm="8"
            md="9"
            class="d-flex justify-start align-center"
          >
            <v-text-field
              height="30"
              outlined
              dense
              placeholder="Nama"
              :rules="isRequiredMainSigner? rules.mainSignName : []"
              v-model="form.mainSigner.mainSignName"
              clearable
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" md="3" class="d-flex align-center">
            <p>
              {{ $_strings.addendumContract.label.ROLE
              }}<span v-if="isRequiredMainSigner" class="red--text">*</span>
            </p>
          </v-col>
          <v-col
            sm="8"
            md="9"
            class="d-flex justify-start align-center"
          >
            <v-text-field
              dense
              outlined
              placeholder="Jabatan"
              :rules="isRequiredMainSigner? rules.mainSignPosition : []"
              v-model="form.mainSigner.mainSignPosition"
              clearable
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" md="3" class="d-flex align-center">
            <p>
              {{ $_strings.addendumContract.label.POWER_OF_ATTORNEY_NUMBER
              }}<span v-if="isRequiredMainSigner" class="red--text">*</span>
            </p>
          </v-col>
          <v-col
            sm="8"
            md="9"
            class="d-flex justify-start align-center"
          >
            <v-text-field
              height="30"
              outlined
              dense
              placeholder="Nomor Surat Kuasa"
              :rules="isRequiredMainSigner? rules.mainSignDocNo : []"
              v-model="form.mainSigner.mainSignDocNo"
              clearable
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" md="3" class="d-flex align-center">
            <p>
              {{ $_strings.addendumContract.label.SIGNED_DATE_POWER_OF_ATTORNEY_NUMBER }}<span v-if="isRequiredMainSigner" class="red--text">*</span>
            </p>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="d-flex align-center">
            <v-menu
              ref="dateFliShow"
              v-model="fliSignedDateShow"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  prepend-inner-icon="mdi-calendar-month"
                  label="Tanggal"
                  single-line
                  :value="form.mainSigner.mainSignDocDate ? dayjs(form.mainSigner.mainSignDocDate).format('DD/MM/YYYY') : null "
                  outlined
                  dense
                  aria-hidden="true"
                  persistent-hint
                  v-bind="attrs"
                  v-on="on"
                  :rules="isRequiredMainSigner? rules.dateRule : []"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.mainSigner.mainSignDocDate"
                no-title
                @input="fliSignedDateShow = false"
                locale="id"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </section>
      <!-- END PT.Fastana Logistik Indonesia -->

      <!-- FORM SHIPPER -->
      <section class="white pa-10 mt-5 elevation-1">
        <v-row>
          <v-col>
            <p>
              <strong>
                {{form.companyType === 'SHIPPER' ? $_strings.addendumContract.label.SHIPPER : $_strings.addendumContract.label.TRANSPORTER}}
              </strong>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" md="3" class="d-flex align-center">
            <p>
              {{ $_strings.addendumContract.label.NAME
              }}<span v-if="isRequiredCompanySigner" class="red--text">*</span>
            </p>
          </v-col>
          <v-col
            sm="8"
            md="9"
            class="d-flex justify-start align-center"
          >
            <v-text-field
              height="30"
              outlined
              dense
              placeholder="Nama"
              v-model="form.companySigner.companySignName"
              :rules="isRequiredCompanySigner? rules.companySignName : []"
              clearable
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" md="3" class="d-flex align-center">
            <p>
              {{ $_strings.addendumContract.label.ROLE
              }}<span v-if="isRequiredCompanySigner" class="red--text">*</span>
            </p>
          </v-col>
          <v-col
            sm="8"
            md="9"
            class="d-flex justify-start align-center"
          >
            <v-text-field
              dense
              outlined
              item-value="id"
              placeholder="Jabatan"
              item-text="roleName"
              v-model="form.companySigner.companySignPosition"
              :rules="isRequiredCompanySigner? rules.companySignPosition : []"
              clearable
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" md="3" class="d-flex align-center">
            <p>
              {{ $_strings.addendumContract.label.POWER_OF_ATTORNEY_NUMBER
              }}<span v-if="isRequiredCompanySigner" class="red--text">*</span>
            </p>
          </v-col>
          <v-col
            sm="8"
            md="9"
            class="d-flex justify-start align-center"
          >
            <v-text-field
              height="30"
              outlined
              dense
              placeholder="Nomor Surat Kuasa"
              aria-hidden="true"
              required
              v-model="form.companySigner.companySignDocNo"
              :rules="isRequiredCompanySigner? rules.companySignDocNo : []"
              clearable
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" md="3" class="d-flex align-center">
            <p>
              {{ $_strings.addendumContract.label.SIGNED_DATE_POWER_OF_ATTORNEY_NUMBER
              }}<span v-if="isRequiredCompanySigner" class="red--text">*</span>
            </p>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="d-flex align-center">
            <v-menu
              ref="companySignDocDateShowDate"
              v-model="companySignDocDateShowDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  prepend-inner-icon="mdi-calendar-month"
                  label="Tanggal"
                  :value="form.companySigner.companySignDocDate ? dayjs(form.companySigner.companySignDocDate).format('DD/MM/YYYY') : null"
                  outlined
                  dense
                  single-line
                  readonly
                  persistent-hint
                  v-bind="attrs"
                  v-on="on"
                  :rules="isRequiredCompanySigner? rules.companySignDocDate : []"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.companySigner.companySignDocDate"
                no-title
                @input="companySignDocDateShowDate = false"
                locale="id"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </section>

      <v-row class="d-flex justify-end mt-2 pa-8">
        <v-btn
          color="primary"
          elevation="2"
          outlined
          @click="$router.push('/main/adendum-kontrak')"
        >
          {{$_strings.contract.label.CLOSE}}
        </v-btn>
        <v-btn
          color="primary"
          class="ml-4"
          @click="submit"
          :loading="donwloading"
        >
          {{$_strings.contract.label.DOWNLOAD_CONTRACT}}
        </v-btn>
      </v-row>
      <!-- END FORM SHIPPER -->
    </v-form>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';

export default {
  mounted() {
    this.fetchCityList();
    this.fetchCompanyList('');
  },
  watch: {
    'form.companyType': function resetForm() {
      this.form.companyId = '';
      this.form.addendumEffectiveDate = null;
      this.setForm();
    },
  },
  data() {
    return {
      donwloading: false,
      showTooltipCompanyName: false,
      form: {
        companyType: 'SHIPPER',
        companyId: null,
        companyName: '',
        contractNo: '',
        mouId: null,
        mouNo: '',
        periodStart: null,
        periodEnd: null,
        addendumLocation: '',
        addendumDate: null,
        addendumEffectiveDate: null,
        mainSigner: {
          mainSignName: 'Andi Candrasatria',
          mainSignPosition: 'Direktur Utama',
          mainSignDocNo: '',
          mainSignDocDate: null,
        },
        companySigner: {
          companySignName: '',
          companySignPosition: '',
          companySignDocNo: '',
          companySignDocDate: null,
        },
      },
      rules: {
        contractFormatType: [(v) => !!v || 'Kontrak format wajib diisi'],
        companyNameRules: [(v) => !!v || 'Nama perusahaan wajib diisi'],
        mainSignPosition: [(v) => !!v || 'Jabatan wajib diisi'],
        mainSignName: [(v) => !!v || 'Nama wajib diisi'],
        partnerRule: [(v) => !!v || 'Mitra Kerja wajib diisi'],
        cityRule: [(v) => !!v || 'Tempat wajib diisi'],
        dateRule: [(v) => !!v || 'Tanggal wajib diisi'],
        companySignDocDate: [(v) => !!v || 'Tanggal ttd surat kuasa wajib diisi'],
        companySignDocNo: [(v) => !!v || 'Nomor Surat Kuasa wajib diisi'],
        companySignPosition: [(v) => !!v || 'Jabatan wajib diisi'],
        companySignName: [(v) => !!v || 'Nama wajib diisi'],
        mainSignDocNo: [(v) => !!v || 'Nomor surat kuasa wajib diisi'],
      },
      cityList: [],
      companyList: [],
      itemsCompanyList: [],
      radioGroupCompany: null,
      isLoadingCompanyList: false,
      isLoadingCityList: false,

      // DATE
      signedDateShow: false,
      fliSignedDateShow: false,
      effectiveDateShow: false,
      companySignDocDateShowDate: false,

      readonly: false,
    };
  },
  computed: {
    isRequiredMainSigner() {
      const { mainSigner } = this.form;
      let isRequiredMainSigner = false;
      Object.keys(mainSigner).forEach((nameObj) => {
        if (mainSigner[nameObj]) {
          isRequiredMainSigner = true;
        }
      });
      return isRequiredMainSigner;
    },
    isRequiredCompanySigner() {
      const { companySigner } = this.form;
      let isRequiredCompanySigner = false;
      Object.keys(companySigner).forEach((nameObj) => {
        if (companySigner[nameObj]) {
          isRequiredCompanySigner = true;
        }
      });
      return isRequiredCompanySigner;
    },
  },
  methods: {
    dayjs,
    async fetchCompanyList(event = '') {
      const { companyType } = this.form;
      let companyName = '';
      if (event) {
        companyName = event.target.value;
      }
      try {
        this.isLoadingCompanyList = true;
        const result = await this.$_services.contract.fetchCompanyListExistsContract(companyName, companyType);
        this.itemsCompanyList = result.data.contents;
      } finally {
        this.isLoadingCompanyList = false;
      }
    },
    async fetchCityList(name = '') {
      const parameterLike = `${name}`;
      try {
        this.isLoadingCityList = true;
        const cities = await this.$_services.locations.cities(parameterLike, 0);
        this.cityList = [...this.cityList, ...cities.data.contents];
      } finally {
        this.isLoadingCityList = false;
      }
    },
    setForm(companyId) {
      if (!companyId) {
        this.form.mouNo = '';
        this.form.companyName = '';
        this.form.contractNo = '';
        this.form.periodStart = '';
        this.form.periodEnd = '';
        this.form.mouId = '';
        this.form.addendumEffectiveDate = null;
        return;
      }
      const filterListCompany = this.itemsCompanyList.find((comp) => comp.companyId === companyId);
      this.form.mouNo = filterListCompany.mouNo;
      this.form.companyName = filterListCompany.companyName;
      this.form.contractNo = filterListCompany.contractNo;
      this.form.periodStart = filterListCompany.mouPeriodStart;
      this.form.periodEnd = filterListCompany.mouPeriodEnd;
      this.form.mouId = filterListCompany.mouId;
    },
    downloadTemplate(url) {
      window.open(url);
    },
    async submit() {
      if (this.$refs.form.validate()) {
        try {
          const companySigner = this.isRequiredCompanySigner ? this.form.companySigner : null;
          const mainSigner = this.isRequiredMainSigner ? this.form.mainSigner : null;
          const data = {
            ...this.form,
            companySigner,
            mainSigner,
          };
          this.donwloading = true;
          const result = await this.$_services.contract.submitTemplateAddendum(data);
          this.downloadTemplate(result.data.url);
        } finally {
          this.donwloading = false;
        }
      } else {
        this.$nextTick(() => {
          const el = this.$el.querySelector('.v-messages.error--text:first-of-type');
          this.$vuetify.goTo(el, { offset: 50 });
        });
        this.$dialog.notify.error('Mohon lengkapi form');
      }
    },
  },
};
</script>
